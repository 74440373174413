/* eslint-disable default-case */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { addCurrentPrice } from '../tools/_add_current_price';
import { fetchData } from '../tools/_fetch-data-helper';

export const fetchFavoriteAccess = createAsyncThunk('favoriteFlats/fetchFavoriteAccess', async (numbers, thunkApi) => {
  const url = `${process.env.REACT_APP_MANAGER_URL}/api/v2/flat/check?blockId=1457&ids=${numbers}`;
  return fetchData(url, thunkApi);
});

const initialState = {
  flatsLocalStorage: JSON.parse(window.localStorage.getItem('flat')) || [],
  filterSelect: {
    cellContent: { id: 1, title: 'Цена по возрастанию', active: true },
    choices: [
      { id: 1, title: 'Цена по возрастанию', active: true },
      {
        id: 2,
        title: 'Цена по убыванию',
        active: false,
      },
      {
        id: 3,
        title: 'Площадь по возрастанию',
        active: false,
      },
      {
        id: 4,
        title: 'Площадь по убыванию',
        active: false,
      },
    ],
  },
  accessFlat: [],
};

export const favoriteFlatSlice = createSlice({
  name: 'favoriteFlats',
  initialState,
  reducers: {
    setLocalFlats(state, action) {
      const item = JSON.parse(window.localStorage.getItem('flat'));
      let newStorage = [];

      if (item === null) {
        newStorage = [...newStorage, { ...action.payload, isActive: true }];
        window.localStorage.setItem('flat', JSON.stringify(newStorage));
        state.flatsLocalStorage = newStorage;
      } else if (item.find(el => el.id === action.payload.id) === undefined) {
        newStorage = [...item, { ...action.payload, isActive: true }];
        window.localStorage.setItem('flat', JSON.stringify(newStorage));
        state.flatsLocalStorage = newStorage.sort((a, b) => {
          return a.currentPrice - b.currentPrice;
        });
      }
    },
    setFilter(state) {
      state.flatsLocalStorage = addCurrentPrice(state.flatsLocalStorage).sort((a, b) => {
        return a.currentPrice - b.currentPrice;
      });
    },
    removeLocalFlats(state, action) {
      const item = JSON.parse(window.localStorage.getItem('flat'));
      const newStorage = item.filter(el => {
        return el.id !== action.payload.id;
      });
      window.localStorage.setItem('flat', JSON.stringify(newStorage));
      state.flatsLocalStorage = newStorage;
    },
    setFilterSelect(state, action) {
      state.filterSelect.cellContent = { ...action.payload, active: true };
      state.filterSelect.choices = state.filterSelect.choices.map(el => {
        if (action.payload.id === el.id) {
          return { ...el, active: true };
        }
        return { ...el, active: false };
      });

      switch (action.payload.title) {
        case 'Цена по возрастанию':
          state.flatsLocalStorage = state.flatsLocalStorage.sort((a, b) => {
            return a.currentPrice - b.currentPrice;
          });

          break;
        case 'Цена по убыванию':
          state.flatsLocalStorage = state.flatsLocalStorage.sort((a, b) => {
            return b.currentPrice - a.currentPrice;
          });
          break;

        case 'Площадь по возрастанию':
          state.flatsLocalStorage = state.flatsLocalStorage.sort((a, b) => {
            return a.area - b.area;
          });
          break;

        case 'Площадь по убыванию':
          state.flatsLocalStorage = state.flatsLocalStorage.sort((a, b) => {
            return b.area - a.area;
          });
          break;
      }
    },
  },
  extraReducers: {
    [fetchFavoriteAccess.pending]: state => {},
    [fetchFavoriteAccess.fulfilled]: (state, action) => {
      state.accessFlat = action.payload;

      state.flatsLocalStorage = state.flatsLocalStorage.filter(flat => {
        if (state.accessFlat[flat.id]) {
          return flat;
        }
        return null;
      });
      window.localStorage.setItem('flat', JSON.stringify(state.flatsLocalStorage));
    },
    [fetchFavoriteAccess.rejected]: state => {
      console.log('Ошибка при запросе на сервер');
    },
  },
});

export const { setLocalFlats, removeLocalFlats, setFilterSelect, setFilter } = favoriteFlatSlice.actions;

export const flatsLocalStorageSelector = state => state.favoriteFlats.flatsLocalStorage;
export const filterSelectSelector = state => state.favoriteFlats.filterSelect;
export const flatsIdAcces = state => state.favoriteFlats.accessFlat;

export default favoriteFlatSlice.reducer;
