import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchData } from '../tools/_fetch-data-helper';
import api from './api';

export const fetchVisualFloor = createAsyncThunk('visualFloorSlice/fetchVisualFloor', async (number, thunkApi) => {
  return fetchData(api.urlFlats, thunkApi);
});

const initialState = {
  floor: null,
  flatsInFloor: null,
  activeFloor: null,
  activeFloorInfo: null,
  visualFloor: null,
  fetchError: false,
  bottomSheetIsOpen: false,
  floorIsLoad: false,
  rooms: null,
  studio: null,
  oneRoom: null,
  twoRoom: null,
  threeRoom: null,
  fourRoom: null,
  fiveRoom: null,
};

export const visualFloorSlice = createSlice({
  name: 'visualFloorSlice',
  initialState,
  reducers: {
    getFloor: (state, action) => {
      state.floor = action.payload;
    },
    setActiveFloor: (state, action) => {
      state.activeFloor = action.payload;
    },
    getFlatsInFloor: (state, action) => {
      state.flatsInFloor = action.payload;
    },
    getActiveFloorInfo: (state, action) => {
      state.activeFloorInfo = action.payload;
    },
    setBottomSheetIsOpen: (state, action) => {
      state.bottomSheetIsOpen = action.payload;
    },
    setFloorIsLoad: (state, action) => {
      state.floorIsLoad = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setStudio: (state, action) => {
      state.studio = action.payload;
    },
    setOneRoom: (state, action) => {
      state.oneRoom = action.payload;
    },
    setTwoRoom: (state, action) => {
      state.twoRoom = action.payload;
    },
    setThreeRoom: (state, action) => {
      state.threeRoom = action.payload;
    },
    setFourRoom: (state, action) => {
      state.fourRoom = action.payload;
    },
    setFiveRoom: (state, action) => {
      state.fiveRoom = action.payload;
    },
  },
  extraReducers: {
    [fetchVisualFloor.pending]: (state, action) => {},
    [fetchVisualFloor.fulfilled]: (state, action) => {
      state.visualFloor = action.payload;
    },
    [fetchVisualFloor.rejected]: (state, action) => {
      state.fetchError = true;
    },
  },
});

export const {
  getFloor,
  setActiveFloor,
  getFlatsInFloor,
  getActiveFloorInfo,
  setBottomSheetIsOpen,
  setFloorIsLoad,
  setRooms,
  setStudio,
  setOneRoom,
  setTwoRoom,
  setThreeRoom,
  setFourRoom,
  setFiveRoom,
} = visualFloorSlice.actions;

export const visualFloorSelector = state => state.visualFloor.visualFloor;
export const fetchErrorSelector = state => state.visualFloor.fetchError;
export const fetchFloor = state => state.visualFloor.floor;
export const fetchActiveFloor = state => state.visualFloor.activeFloor;
export const fetchFlatsInFloor = state => state.visualFloor.flatsInFloor;
export const fetchActiveFloorInfo = state => state.visualFloor.activeFloorInfo;

export const fetchBottomSheetIsOpen = state => state.visualFloor.bottomSheetIsOpen;

export const sheetIsOpenSelector = state => state.visualFloor.bottomSheetIsOpen;
export const fetchFloorIsLoad = state => state.visualFloor.floorIsLoad;
export const roomsSelector = state => state.visualFloor.rooms;
export const studioSelector = state => state.visualFloor.studio;
export const oneRoomSelector = state => state.visualFloor.oneRoom;
export const twoRoomSelector = state => state.visualFloor.twoRoom;
export const threeRoomSelector = state => state.visualFloor.threeRoom;
export const fourRoomSelector = state => state.visualFloor.fourRoom;
export const fiveRoomSelector = state => state.visualFloor.fiveRoom;

export default visualFloorSlice.reducer;
