import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, {
  retries: 2, // Количество попыток
  retryDelay: retryCount => retryCount * 2000, // Задержка между попытками
  retryCondition: error => {
    return error.code === 'ECONNABORTED' || error.response?.status >= 500;
  },
});

export const fetchData = async (url, thunkApi) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
};

function onApiError(thunkApi, error) {
  console.log(error);
  const message = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : JSON.stringify(error.response.data)
      : error.message
      ? error.message
      : JSON.stringify(error)
    : JSON.stringify(error);
  return thunkApi.rejectWithValue(message);
}
